<script>
	import Base from '@backend/Base.vue';
    import BOGen from '@helper/BOGen';
    

	export default {
		name: "Transaction",
		extends: Base,
		data() {
			return {
				Name: "Transaction",
				mrProject:[],
                detailTrx:[{"ac_fullname":""}],
                detailTransfer:[],
                note:null,
                input:{}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
			this.filter.status = ""
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
		},
        methods: {
            formatRupiah(value) {
                if (isNaN(value)) return value;
                value = parseFloat(value);
                return value.toLocaleString('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                });
            },
            viewDetail(v) {
                BOGen.apirest('/' + this.Name, {
					data: v.ats_trx_no,
					type: 'getDetail'
				}, (err, resp) => {
                    this.detailTrx = resp['data']
                    this.note = v.ats_trx_note
                    $('#detailOrder').modal('show')
				}, "POST");
            },
            verifyTransfer(v) {
                BOGen.apirest('/' + this.Name, {
					data: v.ats_trx_no,
					type: 'getDataTransfer'
				}, (err, resp) => {
                    this.detailTransfer = resp['data']
                    this.detailTransfer.total_transfer = this.formatRupiah(v.ats_harga_total)
                    this.detailTransfer.ats_file_transfer = v.ats_file_transfer
                    this.input.ats_status = 'V'
                    this.input.ats_trx_no = v.ats_trx_no
                    $('#verifyTransfer').modal('show')
				}, "POST");
            },
            submitFormVerify(e, callback) {
                if (e && e.btnLoading()) return;
                this.input.type = 'postVerifyTransfer'
                BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
                    console.log(err)
                if (e) e.btnUnloading()
                if (err) {
                    if (err.status == 422) {
                    Object.keys(err.responseJSON).forEach((k) => {
                        $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
                    })
                    }
                    return Gen.info(err.responseJSON.message, "danger", 3000, '.info-modal')
                }
                if (resp.error) {
                    if (this.errorFormCallback) return this.errorFormCallback(resp);
                    if (callback) return callback(resp);
                    return Gen.info(resp.message, "danger", 1000, '.info-modal')
                }
                if (resp.success) {
                    if (this.successFormCallback) return this.successFormCallback(resp);
                    if (callback) return callback(resp);
                    return Gen.info(resp.message, "success", 1000, '.info-modal').then(() => {
                    this.refreshData()
                    this.input = {}
                    $('#verifyTransfer').modal('hide');
                    })
                }
                }, "POST")
            },
            changeStatusTrx(id,status) {
                swal({
                    title: "Confirm!",
                    text:"Are you sure to update this data?",
                    icon: "warning",
                    buttons: ["No, Cancel!", "Yes"],
                    // dangerMode: true,
                }).then((ok) => {
                    if (ok) {
                    BOGen.apirest("/"+this.Name, {type:"status",ats_id:id,ats_status:status},(err,resp)=>{
                        if(err) return swal(err.resp.message,"","warning")
                        swal(resp.message,"","success")
                        this.refreshData()
                    }, "POST")
                    }
                });
            },
            formResi(v) {
                this.input = v
                $('#formResi').modal('show')
            },
            submitResi(e, callback) {
                if (e && e.btnLoading()) return;
                this.input.type = 'updateResi'
                this.input.ats_status = 'D'
                BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
                if (e) e.btnUnloading()
                if (err) {
                    if (err.status == 422) {
                    Object.keys(err.responseJSON).forEach((k) => {
                        $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
                    })
                    }
                    return
                }
                if (resp.error) {
                    if (this.errorFormCallback) return this.errorFormCallback(resp);
                    if (callback) return callback(resp);
                    return Gen.info(resp.message, "danger", 1000, '.info-modal')
                }
                if (resp.success) {
                    if (this.successFormCallback) return this.successFormCallback(resp);
                    if (callback) return callback(resp);
                    return Gen.info(resp.message, "success", 1000, '.info-modal').then(() => {
                    this.refreshData()
                    this.input = {}
                    $('#formResi').modal('hide');
                    })
                }
                }, "POST")
            },
        },
        
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-2">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
                                <div class="col-sm-3">
                                    <DatePicker  @input="search()" v-model="filter.transaction_date" placeholder="Transaction Date"></DatePicker>
                                </div>
								<div class="col-sm-3">
									<select class="form-control" @change="search()" v-model="filter.status">
										<option value="">-- Select Status --</option>
										<option value="N" >Menunggu Transfer</option>
										<option value="T" >Verify Transfer</option>
										<option value="V" >Terverifikasi</option>
										<option value="P" >Packing proccess</option>
										<option value="D" >Delivery Proccess</option>
									</select>
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
                                    <th>#</th>
                                    <th>Nomor Transaksi</th>
                                    <th>Total Harga</th>
                                    <th>Tanggal Pembelian</th>
                                    <th>Jenis Cargo</th>
                                    <th>No Resi</th>
                                    <th>Last Status</th>
                                    <th class="text-center" width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.ats_trx_no}}</td>
									<td>{{formatRupiah(v.ats_harga_total)}}</td>
									<td>{{(v.ats_trx_date||"").dates("format")}}</td>
									<td>{{v.ats_cargo}}</td>
                                    <td>{{ v.ats_no_resi ? v.ats_no_resi : '-' }}</td>
                                    <td>
                                        <StatusLabel :status="v.status" :type="'delivery_proccess'"></StatusLabel>
                                    </td>
									<td class="btn-action text-center">
                                        <a href="javascript:;" @click="viewDetail(v)" class="icon_action view mr-2" title="View Detail" v-tooltip="'view Detail'"><i class="fa fa-eye"></i></a>
                                        <a v-if="v.ats_status == 'T'" href="javascript:;" @click="verifyTransfer(v)" class="icon_action view mr-2" title="verify Transfer" v-tooltip="'verify Transfer'"><i class="fa fa-check"></i></a>
                                        <a v-if="v.ats_status == 'V'" href="javascript:;" class="icon_action" @click="changeStatusTrx(v.ats_id,'P')" v-tooltip="'Packing Proccess'"><i class="fa fa-gift"></i></a>
                                        <a v-if="v.ats_status == 'P' || v.ats_status == 'D' " href="javascript:;" class="icon_action" @click="formResi(v)" v-tooltip="'Delivery Proccess'"><i class="fa fa-truck"></i></a>
                                        <a v-if="v.ats_status == 'D'" href="javascript:;" class="icon_action" @click="changeStatusTrx(v.ats_id,'R')" v-tooltip="'Order Recived'"><i class="fa fa-handshake"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										{{ note }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="mb-0 justify-content-end" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

        <!-- Modal Detail Order -->
        <div class="modal fade" id="detailOrder" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail Order</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    <div class="modal-body">
                        <table class="table table-striped table-bordered">  
                            <thead>
                                <tr>
                                    <th colspan="7">Product</th>
                                </tr>
                                <tr> 
                                    <th>Product Id</th>
                                    <th>Product Name</th>
                                    <th>Color</th>
                                    <th>Size</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val,key) in detailTrx">
                                    <td>{{ val.ap_code }}</td>
                                    <td>{{ val.ap_name }}</td>
                                    <td>{{ val.at_trx_color }}</td>
                                    <td>{{ val.at_trx_size }}</td>
                                    <td>{{ val.at_trx_jml_product }}</td>
                                    <td>{{ formatRupiah(val.ap_admin_price) }}</td>
                                    <td>{{ formatRupiah(val.at_trx_subtotal) }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-striped table-bordered">  
                            <thead>
                                <tr>
                                    <th colspan="2">Customer</th>
                                </tr>
                                <tr> 
                                    <th>Name</th>
                                    <td>{{detailTrx[0].ac_fullname}}</td>
                                </tr>
                                <tr> 
                                    <th>Phone</th>
                                    <td>{{detailTrx[0].ac_phone}}</td>
                                </tr>
                                <tr> 
                                    <th>Email</th>
                                    <td>{{detailTrx[0].ac_email}}</td>
                                </tr>
                                <tr> 
                                    <th>Address</th>
                                    <td>{{detailTrx[0].ac_address}}
                                        <br />
                                        <small class="text-info">
                                            {{ detailTrx[0].ac_kel+', '+detailTrx[0].ac_kec+', '+detailTrx[0].ac_kab+', '+detailTrx[0].ac_prov }}
                                        </small>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Note</th>
                                    <td></td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <!-- <button type="submit" class="btn btn-info">Save changes</button> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Verify Transfer -->

        <div class="modal fade" id="verifyTransfer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Verify Transfer</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <VForm @resp="submitFormVerify" method="post">
                    <div class="modal-body">   
                        <div class="info-modal"></div>                     
                        <table class="table table-striped table-bordered">  
                            <thead>
                                <tr>
                                    <th colspan="7">Transfer Detail</th>
                                </tr>
                                <tr> 
                                    <th>Bank Transfer</th>
                                    <th>Transfer to</th>
                                    <th>Total Transfer</th>
                                    <th>Attachment File</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p class="mb-0 text-info">Bank: {{detailTransfer.customer_bank}}</p>
                                        <p class="mb-0 text-info">Acount: {{detailTransfer.mbc_account_name}}</p>
                                        <p class="mb-0 text-info">No Rek: {{ detailTransfer.mbc_norek }}</p>
                                    </td>
                                    <td>
                                        <p class="mb-0 text-info">Bank: {{detailTransfer.artisan_bank}}</p>
                                        <p class="mb-0 text-info">Acount: {{detailTransfer.mba_account_name}}</p>
                                        <p class="mb-0 text-info">No Rek: {{ detailTransfer.mba_norek }}</p>
                                    </td>
                                    <td>{{ detailTransfer.total_transfer }}</td>
                                    <td> <a target="_blank" :href="uploader(detailTransfer.ats_file_transfer)"> <i class="fa fa-file"></i> Bukti Transfer</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <BoField name="ats_status">
                            <input type="hidden" name="ats_trx_no" :value="input.ats_trx_no">
                            <label>Status:</label>
                            <div class="row">
                                <radio name="ats_status" id="status1" v-model="input.ats_status" option="V" checked>Verify</radio>
                                <radio name="ats_status" id="status2" v-model="input.ats_status" option="RJ">Reject</radio>
                            </div>
                        </BoField>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-info btn-loading">Save changes</button>
                    </div>
                </VForm>
                </div>
            </div>
        </div>
        <!-- Upload Resi -->
        <div class="modal fade" id="formResi" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Update Resi</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <VForm @resp="submitResi" method="post">
                    <div class="modal-body">   
                        <div class="info-modal"></div>  
                        <BoField name="ats_no_resi" :attr="{minlength:3,maxlength:100}" v-model="input.ats_no_resi" mandatory ></BoField>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-info btn-loading">Save changes</button>
                    </div>
                </VForm>
                </div>
            </div>
        </div>
	</div>
</template>